<template>
  <div class="fah">

    <div class="tianbao">
      <div class="tianbaoItem1">
        <img :src="tchtem.headSculptureUrl" alt="" width="100%" height="100%">
      </div>
      <div class="tianbaoItem">
        <div style="width: 100%; height: 25%;">
          <img src="@/assets/imgs/Teacher.png" alt="" width="100%" height="100%">
          <!-- <div style="  display: block;
      font-size: 60px;
      text-decoration: none;
      color: transparent;
      -webkit-text-stroke: 2px #000;
      text-stroke: 2px #000; ">
          文字镂空效果
        </div> -->
        </div>
        <div class="zhiyuan">
          <span class="teacherName">{{ tchtem.teacherName }}</span>
        </div>
        <div class="fuwu">
          <div>
            <span>{{ tchtem.serveNum }}人</span><br>
            <span>服务人次</span>
          </div>
          <div>
            <span>{{ tchtem.great }}</span><br>
            <span>好评率</span>
          </div>
          <!-- <div>
            <span>{{ tchtem.price }}/元</span><br>
            <span>价格</span>
          </div> -->
        </div>
        <!-- <div class="btns">
          <div>咨询客服</div>
          <div>立刻报名</div>
        </div> -->
      </div>
    </div>

    <!-- 课程简介 -->
    <div class="kcJianj">

      <div class="lsjj resouceAndconsultFz">
        <img src="@/assets/imgs/椭圆 2.png" class="tuoyuan" alt="">
        <span>老师介绍 </span>

      </div>
      <!-- 简介 -->
      <div class="kcjj">
        {{ tchtem.teacherDetail }}
      </div>
      <div class="lsjj resouceAndconsultFz">
        <img src="@/assets/imgs/椭圆 2.png" class="tuoyuan" alt="">
        <span>服务内容 </span>
      </div>
      <!-- 该老师对应的咨询列表 -->
      <el-table :data="tchtem.consultsEntityList" class="threeTable" :header-cell-style="{
              backgroundColor: '#f4f4f4',
              color: 'black',
              fontSize: '17px',
              textAlign: 'center',
            }" :cell-style="{
              textAlign: 'center',
            }">
        <el-table-column prop="consultsTitle" label="服务名称" width="130" ></el-table-column>
        <el-table-column prop="consultsIntro" label="服务内容"  ></el-table-column>
        <el-table-column prop="price" label="价格" width="80" ></el-table-column>
        <el-table-column  label="在线咨询"  width="100" >
                <span class="speck teacherBtn" @click="WQDialog = true">咨询</span>
        </el-table-column>
        <el-table-column  label="在线报名" width="130">
          <template slot-scope="scope">
              <span  class="teacherBtn" @click="jikeBuy(scope.row)">马上报名</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
           <el-dialog custom-class="buyDialog" style="margin-top: 15vh!important;" :close-on-press-escape="false" :close-on-click-modal="false" :modal-append-to-body="false"
      :append-to-body="true" title="支付提示" :visible.sync="centerDialogVisible" width="50%" center @close="dialogClose">
      <div style="width:720px;display:flex;background: white;justify-content: space-around;padding-top:15px">
        <!-- 微信 -->
        <div   style="height:275px;margin-right: 15px">
          <div   style="width: 210px; height: 210px;margin: 0 auto;">
            <div v-if="payTimeFlag" style="padding-left: 24px;">
              剩余支付时间
              <span style="color: orange">{{ count }}</span>
            </div>
            <div style="text-align:center ;" v-if="payTimeFlag" ref="qrCode" id="qrCode"></div>
            <div v-if="resetPayflag" style="
                        z-index: 10;
                        width: 210px;
                        height: 210px;
                        background: rgba(0, 0, 0, 0.7);
                        color: white;
                        text-align: center;
                        cursor: pointer;
                        color: white;
                        box-sizing: border-box;
                        padding-top: 80px;
                      ">
              <p>二维码已过期</p>
              <p>请重新刷新</p>
            </div>
            <div style="margin-top: 10px;text-align: center;">
              <img src="@/assets/imgs/sao.png" style="width:20px ;vertical-align: top;" alt="">
              <span>微信扫一扫支付</span>
            </div>
          </div>
        </div>
        <!-- 支付宝 -->
        <div   style="height:275px">
           <div   style="height: 20px;">

            </div>
          <div  style="width: 210px; height: 210px;margin:auto">
            <iframe :srcdoc="qianHtml" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no"
              width="210" height="210" style="overflow:hidden;">
            </iframe>
          </div>
          <div style=" text-align: center;">
            <img src="@/assets/imgs/sao.png" style="width:20px ;vertical-align: top;" alt="">
            <span>支付宝扫一扫支付</span>
          </div>
        </div>
      </div>

    </el-dialog>
    <el-dialog title="咨询客服"
    custom-class="buyDialog" style="margin-top: 15vh!important;"
               :visible.sync="WQDialog"
               width="300px">
        <div>
            <img style="width: 200px;display: block;margin: 0 auto;" src="/static/img/pcWQ.png" alt="">
        </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="WQDialog = false">关闭</el-button>

      </span>
    </el-dialog>
  </div>
</template>

<script>
import { detailTeacher } from '@/api/resourceAndconsult'
import { REQWXPAY, REQZFBPAY, tradeQuery, tradeQueryZFB } from '@/api/pay.js'
import QRCode from 'qrcodejs2'
export default {
  name: 'courseItem',
  data() {
    return {
      tchtem: {},
      WQDialog:false,
      name: '182****3788',
      dialogVisible: false,
      resourcelist: [],
      pros: false,
      textarea1: null,
      textarea2: null,
      kcname: {
        value: '【独家精选】2024年高考填报指南解析',
        jieshao: "2024/高考/志愿填报",
        renshu: '1378',
        jiage: '999',
        yuanjia: "1200.00"
      },
      kcjj: ``,
      consultsId:'',
            centerDialogVisible:false,
       timer: null, // 轮询查询订单
      paytimer: null, // 5分钟倒计时的timer
      timerZFB: null, // 支付宝轮询
      orderNo: '',
      orderNoZFB: '',
      seconds: 300, // 5分钟的秒数
      count: '05分00秒',
      payTimeFlag: false,
      resetPayflag: false,
      qianHtml:''

    };
  },
  methods: {
                // 点击立刻报名按钮
    jikeBuy(item){
      console.log(item.consultsId)
        if(!localStorage.getItem('phonenumber')){
          const channel = new BroadcastChannel('share')
          channel.postMessage(true)
            return
        }

        this.centerDialogVisible = true;
        this.consultsId = item.consultsId
         this.wxpay()
         this.zfbpay()
    },
                // 微信支付
    async wxpay() {
      const self = this
        this.centerDialogVisible = true
        this.resetPayflag = false
        this.payTimeFlag = true
        const obj = {
          phoneNumber: localStorage.getItem('phonenumber'),
          payType: '微信支付',
          productTitle:'咨询名称后端自己去取',
          productType:'3',    //自主卡 智能卡 为 1，课程为2，咨询为3.
          productId:this.consultsId            //自主卡 智能卡为 '', 课程为课程id，咨询为咨询id
        }
        await REQWXPAY(obj).then((res) => {
          self.seconds = 300
          self.Time()
          self.$nextTick(() => {
            const el = this.$refs.qrCode
            document.getElementById('qrCode').innerHTML = ''
            new QRCode(el, {
              width: 200,
              height: 200,
              text: res.data.codeUrl
            })
          })
          self.orderNo = res.data.orderNo
        })
        if (!this.timer) {
          this.timer = window.setInterval(async () => {
            const res = await tradeQuery({ orderNo: self.orderNo })
            if (res.code == 200) {
              this.clearAllTimer()
              this.count = '05分00秒'
              this.payTimeFlag = false
              this.centerDialogVisible = false;   //关闭支付二维码弹框
               this.$alert('购买成功，报考老师会在24小时内与您联系，有任何疑问可联系客服', '购买成功', {
                confirmButtonText: '确定',
              });
            }
          }, 2000)
        }
    },
    // 支付宝支付
    async zfbpay() {
      const self = this

        this.loadingZFB = true
        this.centerDialogVisible = true
       const obj = {
          phoneNumber: localStorage.getItem('phonenumber'),
          payType: '支付宝支付',
           productTitle:'咨询名称后端自己去取',
          productType:'3',    //自主卡 智能卡 为 1，课程为2，咨询为3.
          productId:this.consultsId       //自主卡 智能卡为 '', 课程为课程id，咨询为咨询id
        }
        await REQZFBPAY(obj).then((res) => {
          self.loadingZFB = false
          self.qianHtml = res.data.iframe
          self.orderNoZFB = res.data.outTradeNo
          return
          var form = res.data
          const div = document.createElement('div') // 创建div
          div.innerHTML = form // 将返回的form 放入div
          document.body.appendChild(div)
          document.forms[1].setAttribute('target', '_blank') // 新开窗口跳转
          document.forms[1].submit()
        })
        if (!this.timerZFB) {
          this.timerZFB = window.setInterval(async () => {
            const res = await tradeQueryZFB({ orderNo: this.orderNoZFB })
            if (res.data.status == 'TRADE_SUCCESS') {
              this.clearAllTimer()
              this.centerDialogVisible = false
              this.$alert('购买成功，报考老师会在24小时内与您联系，有任何疑问可联系客服', '购买成功', {
                confirmButtonText: '确定',
              });

            }
          }, 2000)
        }
    },
    dialogClose() {
      this.qianHtml = ''
      this.clearAllTimer()
    },
    // 定时器每过1秒参数减1
    Time() {
      this.paytimer = window.setInterval(() => {
        this.seconds -= 1
        this.countDown()
      }, 1000)
    },
    // 清除所有计时器的公用方法
    clearAllTimer() {
      window.clearInterval(this.timer)
      this.timer = null
      window.clearInterval(this.paytimer)
      this.paytimer = null
      window.clearInterval(this.timerZFB)
      this.timerZFB = null
    },
        // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60))
      d = d < 10 ? '0' + d : d
      let h = parseInt((this.seconds / (60 * 60)) % 24)
      h = h < 10 ? '0' + h : h
      let m = parseInt((this.seconds / 60) % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(this.seconds % 60)
      s = s < 10 ? '0' + s : s
      this.count = m + '分' + s + '秒'
      if (this.count == '00分00秒') {
        this.clearAllTimer()
        document.getElementById('qrCode').innerHTML = ''
        this.resetPayflag = true
        this.payTimeFlag = false
      }
    },

  },
  created() {
    let obj = {
       teacherId: this.$route.query.teacherId
    }
    detailTeacher(this.$route.query.teacherId).then(res => {
      res.data.headSculptureUrl = process.env.VUE_APP_BASE_API + res.data.headSculptureUrl
      this.tchtem = res.data;
    })
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 使用平滑滚动效果
    });
  }

}
</script>

<style lang="scss" scoped>
.fah {
  position: relative;
}

.vid {
  width: 70%;
  height: 600px;
  margin: auto;
  position: relative;
  top: -320px;
}

.kecName,
.kcJianj {
  // width: 61%;
  width: 926px;
  margin: auto;
  position: relative;
  top: -287px;
}

.kcJianj {
  top: -20%;
}

.bti span:nth-child(1) {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}

.bti span:nth-child(2) {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #747474;
}

.bti span:nth-child(3) {
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #747474;
  float: right;
  margin-bottom: 5px;
}

.jiage {
  padding-left: 10px;
  margin-top: 15px;
  width: 100%;
}

.jiage span:nth-child(1) {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff0000;
}

.jiage del {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #747474;
  margin-left: 10px;
}

.jiage div {
  width: 14%;
  height: 50px;
  background-color: #12ae96;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  border-radius: 30px;
  cursor: pointer;
  float: right;
  margin-top: -10px;
}

.lsjj {
  margin-top: 20px;
}
.lsjj .tuoyuan {
  width: 35px;
  vertical-align: sub;
}

.lsjj span {
  font-family: PingFang SC;
  font-weight: 500;
  color: #2f2f2f;
}

.tchr {
  width: 50%;
  margin-top: 50px;
  padding-left: 2%;
  position: relative;
}

.tchr img {
  border-radius: 50%;
}

.tchr span {
  position: absolute;
  top: 40%;
  left: 25%;
  font-size: 18px;
}

.tchrJianj span {
  padding-left: 2%;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #989898;
  line-height: 30px;
}

.kcjj {
  line-height: 40px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #989898;
  padding-left: 3%;
  margin-top: 20px;
}

.kctj {
  // display: flex;
  // justify-content: space-between;
  margin-top: 30px;
  padding-left: 2%;
}

:deep(.el-dialog) {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
  margin-top: 0vh !important;
}

:deep(.el-input__inner) {
  background-color: #e5e5e5;
  height: 53px;
  line-height: 53px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #c5c3c3;
}

.tc {
  position: relative;
  width: 100%;
}

.tc img {
  position: relative;
  width: 80%;
  padding-left: 20%;
  margin-top: -20%;
  z-index: 1;
}

.tc2 {
  position: relative;
  margin-left: 30px;
}

.tc2 img {
  position: relative;
  width: 80%;
  // padding-left: 20%;
  margin-top: -5%;
  z-index: 1;
}

.tc2Text {
  color: #fff;
  position: absolute;
  top: 25%;
  font-size: 24px;
  z-index: 10;
  width: 60%;
  left: 10%;
}

.tc2Text2 {
  position: absolute;
  top: 43%;
  z-index: 10;
  left: 10%;
  color: #000;
  font-size: 15px;
}

.tc2Text3 {
  width: 60%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 10%;
  top: 52%;
  z-index: 15;
}

.tc2Text3 div:nth-child(2),
.tc2Text3 div:nth-child(3) {
  width: 15%;
  height: 53px;
  background-color: #e5e5e5;
  text-align: center;
  line-height: 53px;
  border-radius: 5px;
  margin: 0 5px;
}

.tc2bag {
  background-color: #12b098 !important;
  color: #fff;
}

.btn {
  background-color: #12ae96;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  border-radius: 30px;
  width: 60%;
  position: absolute;
  left: 10%;
  top: 84%;
  z-index: 15;
}

.tcGuanbi img {
  position: relative;
  width: 6%;
  float: right;
  margin-top: 5%;
  margin-right: 15%;
  z-index: 1000;
}

.tcText {
  width: 50%;
  position: absolute;
  font-size: 20px;
  color: #00000093;
  top: 53%;
  left: 24%;
  line-height: 40px;
  z-index: 2;
}

.tcbtn {
  width: 58%;
  height: 60px;
  line-height: 60px;
  background-color: #12ae96;
  text-align: center;
  border-radius: 10px;
  color: #ffffff;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  position: absolute;
  left: 20%;
  margin-top: 10px;
  cursor: pointer;
}

.tcText2 {
  top: 70%;
  left: 25%;
  font-size: 18px;
}

.text1 {
  position: absolute;
  top: 26%;
  color: #fff;
  font-size: 18px;
  left: 43%;
  z-index: 15;
}

.tcthersFlex {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  margin-left: 3%;
}

.tcthers {
  width: 28%;
  height: 300px;
  box-shadow: 0px 0px 10px 1px rgba(157, 157, 157, 0.5);
  border-radius: 5px;
  padding-top: 5%;
}

.rcthersItem {
  position: relative;
  width: 60%;
  height: 60%;
  margin: auto;
  // border: 1px solid #000;
  margin-top: 5%;
}

.rcthersItem img {
  position: relative;
  display: block;
  width: 60%;
  margin: auto;
  z-index: 5;
}

.tig {
  position: relative;
  width: 60%;
  height: 36px;
  background-color: #ffce63;
  line-height: 36px;
  text-align: center;
  border-radius: 20px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: auto;
  margin-top: -15px;
  z-index: 10;
}

.rcthersItem div {
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1b1b1b;
}

.rcthersItem div:nth-child(3) {
  margin-top: 10%;
}

.rcthersItem div:nth-child(4) {
  font-size: 14px;
  color: #a8a8a8;
  margin-top: 3%;
}

.rcthersItem div:nth-child(5) {
  padding: 5px;
  margin: auto;
  width: 100%;
  color: #30c9b2;
  border: 1px solid #30c9b2;
  border-radius: 20px;
  margin-top: 30%;
  cursor: pointer;
}
.tianbao {
  // width: 70%;
  width: 1065px;
  height: 355px;

  margin: auto;
  margin-bottom: 50px;
}

.tianbaoItem,
.tianbaoItem1 {
  width: 40%;
  height: 90%;
  float: left;
  margin-left: 5%;
  margin-top: 5%;
  border: 1px solid #fff;
}
.tianbaoItem1 {
  background-image: url(../../assets/imgs/bjng.png);
  background-size: 100% 100%;
}

.zhiyuan {
  width: 100%;
  height: 25%;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #090909;
  line-height: 40px;
  margin-top: 2%;
}

.zhiyuan span {
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: 10px solid #74d19d;
}

.text {
  width: 100%;
  height: 25%;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #12ae96;
}

.text span {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #12ae96;
}

.btns {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
}

.btns div:nth-child(1) {
  width: 38%;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 30px;
  color: #12ae96;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  cursor: pointer;
  border: 3px solid #12ae96;
}

.btns div:nth-child(2) {
  width: 59%;
  height: 45px;
  line-height: 40px;
  background-color: #12ae96;
  text-align: center;
  border-radius: 30px;
  color: #ffffff;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  cursor: pointer;
}

.fuwu {
  width: 50%;
  height: 25%;
  display: flex;
  justify-content: space-between;
  // margin-top: 2%;
}

.fuwu div {
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC;
  color: #12ae96;
  font-weight: 500;
}

.fuwu div span {
  display: inline-block;
  margin-top: 10px;
}
.el-table .teacherBtn{
  width: 110px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
  background: #30c9b2;
  color: white;
  border-radius: 15px;
  display: inline-block;
}
.el-table .speck{
    background: #ffce63;
    width: 60px;
}
</style>
